@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
:root {
  --base-width-size: calc(100vw / 12 - 2vw);
  --base-unit-size: calc(calc(100vw / 12 - 2vw) / 100 );

  --color-palette-1: 
  #ffd166;
  --color-palette-2: 
  #ef476f;
  --color-palette-3:
   #06d6a0;
  --color-palette-4: 
  #118ab2;
}

* {
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
  width: 100%;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: stretch;
  /* min-height: 90vh; */
  padding: 0;
  margin: 0;
  width: 100%;
  /* overflow: hidden; */
  flex-direction: column;
}

.flex-grid {
  display: flex;
  /* border: 1px solid #454545; */
  max-width: 90%;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding-left: 10px;
  border: 3px solid;
  border-radius: 5px;
  border-color: var(--color-palette-2);
  padding: var(--base-width-size);
}

.letter-item {
  display: flex;
  padding: 5px;
  border: 1px solid var(--color-palette-2);
  margin: 3px;
  /* font-size: 10vmin; */
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: var(--base-width-size);
  width: calc(var(--base-width-size));
  height: calc(var(--base-width-size));
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(239, 71, 111, 0.4) 5px 5px, rgba(239, 71, 111, 0.3) 10px 10px, rgba(239, 71, 111, 0.2) 15px 15px, rgba(239, 71, 111, 0.1) 20px 20px, rgba(239, 71, 111, 0.05) 25px 25px;  

  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

@media (max-width: 1000px) {
  .letter-portrait {
    font-size: var(--base-width-size);
    width: calc(var(--base-width-size));
    height: calc(var(--base-width-size));
    justify-content: center;
    align-items: center;
  }
}


.body {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  /* border: 1px dotted #454545; */
  background-color: var(--color-palette-3);
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.tborder {
  border: 1px none #454545;
}

/* Pallette: https://coolors.co/palette/33a8c7-52e3e1-a0e426-fdf148-ffab00-f77976-f050ae-d883ff-9336fd */

.it-1 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2s ease infinite;
}

.it-2 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.1s ease infinite;
}

.it-3 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom:  -1vmin;
  animation: bounce2 2.2s ease infinite;
}

.it-4 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.3s ease infinite;
}

.it-5 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.4s ease infinite;
}

.it-6 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom:  -1vmin;
  animation: bounce2 2.5s ease infinite;
}

.it-7 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.6s ease infinite;
}

.it-8 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.7s ease infinite;
}

.it-9 {
  background-color: var(--color-palette-1);
  color: var(--color-palette-2);
  margin-bottom: -1vmin;
  animation: bounce2 2.8s ease infinite;
}

@keyframes shake {
	0%, 100% {transform: translateX(0);}
	10%, 30%, 50%, 70%, 90% {transform: translateX(-10px);}
	20%, 40%, 60%, 80% {transform: translateX(10px);}
}


.coming-soon {
  font-family: 'Dancing Script', cursive;
  font-weight: 200;
  font-size: calc(var(--base-width-size));
  text-shadow: var(--color-palette-2) calc(var(--base-unit-size) * 3) calc(var(--base-unit-size) * 5);
  color: var(--color-palette-1);
  display: flex;
}

.gradient-animation {
  animation: typing 3.5s ease infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.flexbox-row{
  display: flex;
  flex-direction: column;
}
.flex-item{
  width: calc(var(--base-unit-size)*4);
  margin-left: 10px;
}

.spec{
  font-size: calc(var(--base-unit-size) * 32);
}